/**
 * Created by razial on 16.01.2025.
 */

var ShortMetaLogic = function () {
    this.currentLocationId = 0;
};

ShortMetaLogic.prototype.reset = function () {
    this.currentLocationId = 0;
};

ShortMetaLogic.prototype.getMainLocation = function () {
    return this.currentLocationId;
};

ShortMetaLogic.prototype.getLocationClass = function () {
    return ShortMetaLocation;
};

ShortMetaLogic.prototype.getMainScene = function () {
    return ShortMetaScene;
};

ShortMetaLogic.prototype.listAvailableLocations = function () {
    var ids = [];
    var id = 0;

    var exist = function () {
        return bundles["episode_" + id];
    };

    while (exist()) {
        ids.push(id);
        id++;
    }

    return ids;
};

ShortMetaLogic.prototype.listActiveLocations = function () {
    var locationId = this.currentLocationId;

    var available = cleverapps.meta.listAvailableLocations();
    if (available.length && available[available.length - 1] < locationId) {
        locationId = available[available.length - 1];
    }

    return [locationId];
};

ShortMetaLogic.prototype.isCompleted = function () {
    var available = cleverapps.meta.listAvailableLocations();
    return !available.length || available[available.length - 1] < this.currentLocationId;
};

ShortMetaLogic.prototype.moveNextLocation = function () {
    this.currentLocationId += 1;
};

ShortMetaLogic.prototype.processChatMessage = function () {

};