/**
 * Created by Ivan on 03.01.2025
 */

var BonusLevelView = cc.Node.extend({
    ctor: function (board, timeLeft) {
        this._super();

        var chestHorizontal = this.chestHorizontal = new BonusLevelChest(timeLeft);
        this.addChild(chestHorizontal, -1);
        cleverapps.aims.registerTarget("bonus_chest", chestHorizontal.chest);

        var chestVertical = this.chestVertical = new BonusLevelChest(timeLeft, true);
        this.addChild(chestVertical, -1);
        cleverapps.aims.registerTarget("bonus_chest_vertical", chestVertical.chest);

        this.updateSize();
        this.setupChildren();

        board.on("collectCoin", this.openChest.bind(this), this);
    },

    getOverlappingNodes: function () {
        return cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL ? this.chestHorizontal : this.chestVertical;
    },

    openChest: function () {
        if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
            this.chestHorizontal.open();
        } else {
            this.chestVertical.open();
        }
    },

    updateSize: function () {
        this.setContentSize2(cleverapps.resolution.getSceneSize());
    },

    setupChildren: function () {
        var styles = cleverapps.styles.BonusLevelView;

        this.setPositionRound(styles);

        if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
            this.chestHorizontal.show(styles.horizontal);
            this.chestVertical.hide();
        } else {
            this.chestHorizontal.hide();
            this.chestVertical.show(styles.vertical);
        }
    }
});

BonusLevelView.getTarget = function () {
    return cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL ? "bonus_chest" : "bonus_chest_vertical";
};

cleverapps.styles.BonusLevelView = {
    x: { align: "center", dx: 0 },
    y: { align: "center", dy: 0 },
    
    horizontal: {
        x: { align: "right", dx: -220 },
        y: { align: "bottom", dy: -50 }
    },

    vertical: [
        { x: { align: "center", dx: 0 }, y: { align: "top", dy: -50 } },
        { x: { align: "center", dx: 0 }, y: { align: "top", dy: -100 } },
        { x: { align: "center", dx: 0 }, y: { align: "top", dy: -150 } },
        { x: { align: "center", dx: 0 }, y: { align: "top", dy: -250 } }
    ]
};